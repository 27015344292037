import React from 'react'
import Styles from './frontpagethemes.module.css';
import { Link } from "gatsby"
const Frontpagethemes = () => {
  return (
      <div className={Styles.wrapper}>

    <div className={Styles.container}>
        <Link to="/abstract-phone-cases" className={Styles.BigTheme}>
            <h3 className={Styles.ThemeHeader}>Abstract</h3>
        </Link>
        
      
            <Link to="/trippy-phone-cases" className={Styles.TrippyTheme}><h3 className={Styles.ThemeHeader}>Trippy</h3></Link>
         
        
            <Link to="/mandala-phone-cases" className={Styles.MarbleTheme}><h3 className={Styles.ThemeHeader}>Mandala</h3></Link>
       </div>
  
    </div>
  )
}

export default Frontpagethemes