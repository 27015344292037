import React from "react"
import { Link } from "gatsby"
import Styles from './brandselectormobile.module.css';

const Brandselectormobile = () => {


  return (
    <>
       <h3 className={Styles.headerText}>Choose Your Phone</h3>
    <div className={Styles.container}>
    <Link to="/iphonecases" className={Styles.branddiv}>
       
       </Link>
       <Link to="/samsungcases" className={Styles.branddivright}>
      
  
       </Link>
      <img className={Styles.Mobileimage} src={require('../../images/mobilebanner.webp')} alt="iphone/samsung hero image. select left side for iphone and right side for samsung."/>
     
    </div>
    </>
  )
}

export default Brandselectormobile
