import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ProductsListingItem from "./ProductsListingItem"
import Styles from './ProductListing.module.css'

//THis one is the front page recommended products



const ProductsListing = () => {
  const PRODUCTS_LISTING_QUERY = useStaticQuery(graphql`
  query ProductsListingQuery {
    allShopifyCollection(filter: {title: {eq: "Featured"}}) {
      edges {
        node {
          products {
    
        title
            id
            handle
            description
            productType
            variants {
              shopifyId
              title
              price
              availableForSale
            }
            images {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
        
      }
    }
  }
}
}
`
  )
  return (
    <div>

      <div className={Styles.wrapper} itemScope itemType="https://schema.org/Collection">

        {
          PRODUCTS_LISTING_QUERY.allShopifyCollection.edges[0].node.products.map((product) => {
            return (
              <>
                <ProductsListingItem key={product.id} product={product} />
              </>
            )
          })
        }

      </div>

    </div>
  )
}

export default ProductsListing
