import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import RecommendedProducts from "../components/ProductCard/FrontPageRecommendedProducts"
import './css/index.css'
import BrandSelector from '../components/brandcatselector/brandselector'
import BrandSelectorMobile from '../components/brandcatselector/brandselectormobile'
import FrontPageThemes from '../components/FrontPageThemesSelection/frontpagethemes'
const IndexPage = () => (
  <Layout>
    <Helmet>

      <title>Colorful Phone Cases</title>
      <meta name="description" content="Shop a vast selection of colorful phone cases with a large variety of themes from beautiful abstract art to trippy and nature inspired designs."></meta>
      <meta name="keywords" content="colorful phone cases, colorful iPhone Cases, colorful iPhone Case, colorful phone case, colorful samsung case, colorful samsung galaxy phone cases, trippy iPhone cases, trippy Samsung cases, trippy android cases, psychedelic phone cases, psychedelic iPhone cases, psychedelic Samsung cases, hippie phone cases, abstract phone cases, colorful abstract phone cases" />
      <meta property="og:type" content="website" />
      <meta name="og:title" content="Colorful Phone Cases" />
      <meta property="og:description" content="Shop a vast selection of colorful phone cases with a large variety of themes from beautiful abstract art to trippy and nature inspired designs." />
      <meta property="og:image" content="../images/CustomogThumbnail.png" /> 
    </Helmet>
    <div>
        <h3 id="featured-header-hero">Choose a Phone</h3>
    </div>
     
    <BrandSelector />
    

    <BrandSelectorMobile />
    <h3 id="featured-header-theme">Or Choose a Theme</h3>
    <FrontPageThemes />
    <div id="featured-container">
      <h3 id="featured-header">Featured Phone Cases</h3>

      <RecommendedProducts />

    </div>


  </Layout >
)

export default IndexPage
