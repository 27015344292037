import React from "react"
import { Link } from "gatsby"
import Styles from './brandselector.module.css';

const Nav = () => {


  return (
    
    <div className={Styles.brandcontainer}>
    
 
      <Link to="/iphonecases" className={Styles.IphoneSide}>
        <h2 className={Styles.iside}>iPhone Cases</h2>
      </Link>
      <Link to="/samsungcases" className={Styles.SamsungSide}>
        <h2 className={Styles.samside}>Samsung Cases</h2>
      </Link>
      
    </div>
  )
}

export default Nav
